.pagination__container {
  width: 80%;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 20px 0;

  & a {
    font-size: larger;
    padding: 6px 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid rgb(212 212 216);
    color: black;
    cursor: pointer;
    background-color: rgb(212 212 216);

    &:hover {
      color: white;
      background-color: black;
      border: 1px solid black;
    }
  }
}

.pagination__active a {
  color: white;
  background-color: rgb(51 65 85);
  border-color: rgb(51 65 85);
}

.pagination__disabled a {
  color: rgb(156 163 175);
  background-color: rgb(212 212 216);
  &:hover {
    color: rgb(156 163 175);
    background-color: rgb(212 212 216);
    cursor: auto;
  }
}
