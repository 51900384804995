.logo {
  height: 3rem;
  z-index: 1;
}

.logor {
  height: 30rem;
  z-index: 1;
}

.product__img {
  height: 35rem;
  z-index: 1;
}

.footer__rlogo,
.footer__lavologo {
  height: 5rem;
  z-index: 1;
}

.detail__image {
  height: 40rem;
  min-width: 20rem;
}

.card__container {
  height: 35rem;
}

.card__wrapper {
  min-width: 22rem;
}
